import styles from "@/apps/Frictionless/MagicLink/MagicLink.module.css";
import React, {useState} from "react";
import Dorsia from "@/assets/images/Dorsia.svg?react";
import useSWRMutation from 'swr/mutation'
import Config from "@/lib/Config.ts";

const postEmail = async ([path, requestBody]) => {
    const url = new URL(`${Config.data.uri.api}${path}`);
    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        referrerPolicy: "strict-origin-when-cross-origin",
        headers: {
            'Content-Type': 'application/json',
            'X-Dorsia-Client': `widget build:${Config.data.build},environment:${Config.data.environment}`,
            'Accept': 'application/json',
            'X-API-Version': '20240524',
        },
        body: JSON.stringify(requestBody)
    })

    if (!response.ok) {
        throw new Error('Failed to fetch')
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const jsonResponse = await response.json();

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return,@typescript-eslint/no-unsafe-member-access
    return jsonResponse?.data ? jsonResponse.data : jsonResponse;
}

const MagicLink: React.FC = () => {

    const [email, setEmail] = useState<string>('');
    const [sentLink, setSentLink] = useState<boolean>(false);

    const {trigger} = useSWRMutation(['v20240524/magic-link-login', {email: email}], postEmail);

    const onSubmit = (event: React.SyntheticEvent) => {
        event.preventDefault();

        void trigger();
        setTimeout(() => {
            setSentLink(true);
        }, 500);
    }

    return (
        <div className={styles.section}>
            <Dorsia className={styles.dorsia}/>

            <hr/>

            <div className="content">
                <div className="section">
                    {sentLink && <h3>Check your email for the magic link</h3>}
                    {!sentLink && <h3>Enter the email address on your reservation</h3>}
                    {!sentLink &&
                        (
                            <form className="section" onSubmit={onSubmit}>
                                <div className={styles.field}>
                                    <label>Email Address</label>
                                    <input
                                        type="email"
                                        placeholder="name@example.com"
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </div>

                                <input type="submit" className="nextButton" disabled={email === ''}
                                       value={`Next`}/>
                            </form>)
                    }
                </div>
            </div>
        </div>
    )
}

export default MagicLink;