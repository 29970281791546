import * as Sentry from '@sentry/react';
import clsx from 'clsx';
import {useAtomValue, useSetAtom} from 'jotai';
import React, {useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import Header from '../Header/Header.tsx';
import {updateUser} from '../../../lib/api.ts';
import 'react-phone-number-input/style.css';
import RemainingTime from '../RemainingTime/RemainingTime.tsx';
import {
    AuthAtom,
    reloadReservationAtom,
    ReservationAtom,
    useDirectLinkState,
} from '../lib/state.ts';
import styles from './Info.module.css';
import dayjs from 'dayjs';
import InputMask from 'react-input-mask';
import {Exception} from "@sentry/react";

const Info: React.FC = () => {
    const navigate = useNavigate();
    const auth = useAtomValue(AuthAtom);
    const reservation = useAtomValue(ReservationAtom);
    const reloadReservation = useSetAtom(reloadReservationAtom);
    const [directLink, setDirectLink] = useDirectLinkState();
    const [nexting, setNexting] = useState<boolean>(false);
    const userBirthday = reservation?.user.birthday
        ? dayjs(reservation?.user.birthday).format('MM/DD/YYYY')
        : null;
    const [birthdayValue, setBirthdayValue] = useState(userBirthday);
    const [yearOfBirth, setYearOfBirth] = useState(2000);
    const [validBirthday, setValidBirthday] = useState<boolean>(false);
    const [birthdayChanged, setBirthdayChanged] = useState<boolean>(false);
    const [onnextError, setOnnextError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState('');

    const ready =
        !!directLink.first_name && !!directLink.last_name && !!directLink.email;

    const onSubmit = (event: React.SyntheticEvent) => {
        event.preventDefault();

        if (
            (reservation?.restaurant.id == 317 ||
                reservation?.restaurant.id == 401) &&
            userBirthday === null
        ) {
            if (yearOfBirth <= -21 && validBirthday) {
                void onNext();
            }
        } else {
            void onNext();
        }
    };

    const onNext = async () => {
        try {
            setNexting(true);
            setErrorMessage('');

            const user_response = await updateUser(auth!.token, {
                first_name: directLink.first_name!,
                last_name: directLink.last_name!,
                email: directLink.email!,
                birthday: directLink.birthday || '',
            });

            if (!user_response.status) {
                setNexting(false);
                setOnnextError(true);
                return;
            }

            setDirectLink({
                first_name: user_response.user.first_name,
                last_name: user_response.user.last_name,
                email: user_response.user.email,
                birthday: user_response.birthday,
            });

            setNexting(false);

            reloadReservation();

            navigate(`../payment`);
        } catch (e: unknown) {
            console.error(e);
            setNexting(false);
            setErrorMessage(e.message);
            Sentry.captureException(e);
        }
    };

    const onFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDirectLink({
            first_name: event.currentTarget.value,
        });
    };

    const onLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDirectLink({
            last_name: event.currentTarget.value,
        });
    };

    const onEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDirectLink({
            email: event.currentTarget.value,
        });
    };

    const validateBirthday = (bday: string) => {
        setBirthdayChanged(true);
        setBirthdayValue(bday);
        const today = new Date();
        const birthday = dayjs(bday);
        const checkValid = dayjs(birthday, 'MM/DD/YYYY', true).isValid();
        const years = birthday.diff(today, 'year');
        const dbBirthdayFormat = birthday.toISOString();

        setValidBirthday(checkValid && years < 0 && years > -100);
        setYearOfBirth(years);
        setDirectLink({
            birthday: dbBirthdayFormat,
        });
    };

    const checkBirthday = (event: React.ChangeEvent<HTMLInputElement>) => {
        validateBirthday(event.target.value);
    };

    const eventDayTime = `${reservation?.reservation_at_tzd.format(
        'ddd, MMMM D'
    )} - ${reservation?.reservation_at_tzd.format('h:mm a')}`;

    return (
        <>
            <Header
                title="Enter your details"
                step={1}
                show_image={false}
                isEvent={reservation?.restaurant.type == 'event'}
            />

            {directLink?.update_user_error && (
                <div className={clsx('content', styles.header_error)}>
                    There was an issue with your details.
                </div>
            )}

            <div className="content">
                {reservation && <h2>{reservation.restaurant?.name}</h2>}

                <form className="section" onSubmit={onSubmit}>
                    {reservation?.restaurant.type == 'event' ? (
                        <div>{eventDayTime}</div>
                    ) : (
                        <Link to="../" className={styles.back}>
                            {'<-'} Back
                        </Link>
                    )}

                    <h3>Your details</h3>

                    {directLink?.update_user_error && (
                        <div className={styles.error}>{directLink?.update_user_error}</div>
                    )}

                    <div className={styles.field}>
                        <label>First name</label>
                        <input
                            type="text"
                            placeholder="First name"
                            value={directLink.first_name ?? ''}
                            onChange={onFirstNameChange}
                            autoFocus={directLink.first_name === undefined}
                        />
                    </div>

                    <div className={styles.field}>
                        <label>Last name</label>
                        <input
                            type="text"
                            placeholder="Last name"
                            value={directLink.last_name ?? ''}
                            onChange={onLastNameChange}
                        />
                    </div>

                    <div className={styles.field}>
                        <label>Email address</label>
                        <input
                            type="email"
                            placeholder="Enter email address"
                            value={directLink.email ?? ''}
                            onChange={onEmailChange}
                        />
                    </div>

                    {(reservation?.restaurant.id == 317 ||
                        reservation?.restaurant.id == 401) &&
                    userBirthday === null ? (
                        <div className={styles.field}>
                            <label>Birthday</label>
                            <InputMask
                                type="text"
                                placeholder="MM/DD/YYYY"
                                onChange={(event) =>
                                    setBirthdayValue(event.currentTarget.value)
                                }
                                mask="99/99/9999"
                                inputMode="numeric"
                                value={birthdayValue}
                                onBlur={checkBirthday}
                            />

                            {yearOfBirth >= -20 && validBirthday ? (
                                <div className={styles.birthdayerror}>
                                    Sorry, this is a 21 or older event.
                                </div>
                            ) : null}

                            {!validBirthday && birthdayChanged ? (
                                <div className={styles.birthdayerror}>Not a valid birthday</div>
                            ) : null}
                        </div>
                    ) : null}

                    {onnextError && (
                        <div className={styles.birthdayerror}>
                            Sorry, there was an error. Please try again.
                        </div>
                    )}

                    {errorMessage.length > 0 && (
                        <div className={styles.birthdayerror}>
                            {errorMessage}
                        </div>
                    )}

                    <input
                        type="submit"
                        className="nextButton"
                        disabled={nexting || !ready}
                        value={`Next${nexting ? '...' : ''}`}
                    />
                </form>

                <RemainingTime/>
            </div>
        </>
    );
};

export default Info;
