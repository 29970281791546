import clsx from "clsx";
import dayjs from "dayjs";
import {useAtomValue} from "jotai";
import React, {Fragment} from "react";
import {useNavigate} from "react-router-dom";
import Dorsia from '../../../assets/images/Dorsia.svg?react';
import Card, {CardNames} from "../../../components/Card/Card.tsx";
import {ReceiptItem, ReservationReceipt, ReceiptTotal, TipOption} from "../../../lib/types.ts";
import User_dark from '../../../assets/images/User_dark.svg?react';
import GenericInfo from '../../../assets/icons/GenericInfoIcon.svg?react';
import {
    AuthAtom,
    FrictionlessStateAtom, LoadReceiptAtom,
    ReceiptAtom, ReservationPreviewAtom, useUpdateTip
} from "../lib/state.ts";
import {FrictionlessState} from "../lib/types.ts";

import styles from './Details.module.css';

function addCents(value) {
    // We should really use CurrencyFormatter.format() instead of this function to format currencies
    // But for that the API should return doubles/floats
    // Instead the API currently returns formatted strings for currencies
    // with inconsistent format for cents (sometimes present other times missing)

    // API currently returns one of these currencies, we may need to revise this code as we expand this list
    const symbols = ['$', '£', '€', 'AED'];

    if (symbols.some(v => value.includes(v)) && ! value.includes('.')) {
        value += '.00';
    }

    return value;
}

const Details: React.FC = () => {
    const navigate = useNavigate();
    const receipt = useAtomValue(ReceiptAtom);
    const preview = useAtomValue(ReservationPreviewAtom);
    const state = useAtomValue(FrictionlessStateAtom);
    const auth = useAtomValue(AuthAtom);
    const checked = useAtomValue(LoadReceiptAtom);

    if (!auth) {
        navigate('../phone'); // I'm impressed that this is working.  I assumed it would not.  react-magic is weird
    }

    if (!preview) {
        return <></>
    }

    const updated = receipt?.receipt?.last_updated_from_integration
        ? dayjs(receipt.receipt.last_updated_from_integration).utc()
        : dayjs(checked).utc();
    const updated_local = updated.tz(preview.restaurant.timezone);
    const updated_title = updated_local.format('YYYY-MM-DD h:mm a');
    const updated_time = updated_local.format('h:mm a');

    return (
        <div className={styles.section}>
            <Dorsia className={styles.dorsia} />

            <hr />

            <h2>{preview.restaurant?.name}</h2>

            <h3>{preview.reservation_time_pretty} <User_dark /> {preview.party_size}</h3>
            <h3>{addCents(preview.table_minimum__currency)} prepaid minimum spend</h3>

            <div className={styles.updated} title={updated_title}>Last updated {updated_time}</div>

            <hr />

            {receipt
                ? (
                    <>
                        <Receipt receipt={receipt} state={state} />

                        {receipt.receipt.status === 'In-Order' && (
                            <div className={styles.just_leave}>(You can just leave when you're done)</div>
                        )}

                        {receipt.receipt.status === 'Payment-Failed' && (
                            <div className={styles.payment_failed}>Your card was declined. Our team will be in touch with you shortly.</div>
                        )}
                    </>
                )

                : (
                    <p className={styles.pregame}>Your check will be live shortly.</p>
                )
            }
        </div>
    )
}

interface ReceiptProps {
    receipt: ReservationReceipt
    state: FrictionlessState
}

const Receipt: React.FC<ReceiptProps> = ({receipt, state}) => {
    const CurrencyFormatter = new Intl.NumberFormat(undefined, {
        style: 'currency',
        currency: state.currency
    });

    return (
        <>
            {receipt.receipt.items.map((item: ReceiptItem, index: number) => {
                return (
                    <div key={index} className={styles.grid}>
                        <div>{item.quantity} {item.name}</div>
                        <div>{item.price__currency}</div>
                    </div>
                );
            })}

            {receipt.receipt.items.length > 0 && (
                <hr />
            )}

            { receipt.receipt.balance < 0 && (
                <>
                    <div className={styles.grid}>
                        <div>Spent so far</div>
                        <div>{receipt.receipt.sub_total__currency}</div>
                    </div>

                    <div className={styles.grid}>
                        <div>Remaining spend</div>
                        <div className={styles.remaining_spend}>{receipt.receipt.balance__currency}</div>
                    </div>

                    <hr />
                </>
            )}

            { receipt.receipt.subtotals.map((item: ReceiptTotal, index: number) => {
                return (
                    <div key={index} className={styles.grid}>
                        <div>{item.label}</div>
                        <div>{addCents(item.amount)}</div>
                    </div>
                );
            })}

            { (! receipt.receipt.subtotals || receipt.receipt.subtotals.length < 1) && (
                <div className={styles.grid}>
                    <div>Subtotal</div>
                    <div>{receipt.receipt.reached_sub_total__currency}</div>
                </div>
            )}

            <hr />

            {
                receipt.receipt.tip_included
                    ? (
                        <>
                            {
                                receipt.receipt.status === 'In-Order' && (
                                    <div className={styles.additional_tip}>
                                        <div className={styles.description}>
                                            <GenericInfo />
                                            <p>Tip at {receipt.restaurant.name} is already included in the tab.  You can choose to add additional tip below.</p>
                                        </div>
                                        <div className={styles.gratuity}>
                                            <Gratuity amount={0} />
                                            <Gratuity amount={10} />
                                            <Gratuity amount={15} />
                                            <Custom />
                                        </div>
                                    </div>
                                )
                            }
                            <div className={styles.grid}>
                                <div>Additional Tip</div>
                                <div>{receipt.receipt.additional_tip.amount__currency}</div>
                            </div>
                        </>
                    )
                    : (
                        <>
                            { receipt.receipt.status === 'In-Order' && (
                                <div className={styles.gratuity}>
                                    { receipt.restaurant.tip_options.map((item: TipOption, index: number) => {
                                        return (
                                            <Fragment key={index}>
                                                { item.amount && (
                                                    <Gratuity amount={item.amount} />
                                                )}
                                                { ! item.amount && item.label === 'Custom' && (
                                                    <Custom />
                                                )}
                                            </Fragment>
                                        );
                                    })}
                                </div>
                            )}
                            <div className={styles.grid}>
                                <div>Tip</div>
                                <div>{receipt.receipt.tip_total__currency}</div>
                            </div>
                        </>
                    )
            }

            { receipt.receipt.tax_total__currency && (
                <>
                    <div className={styles.grid}>
                        <div>Tax</div>
                        <div>{receipt.receipt.tax_total__currency}</div>
                    </div>
                </>
            )}
            <div className={clsx(styles.grid, styles.bigger)}>
                <div>Total</div>
                <div>{receipt.receipt.display_total__currency}</div>
            </div>
            <div className={styles.grid}>
                <div>Prepaid minimum spend</div>
                <div>-{addCents(receipt.receipt.table_minimum_spend__currency)}</div>
            </div>

            <hr />

            <div className={clsx(styles.grid, styles.bigger)}>
                {
                    receipt.receipt.status === 'In-Order'
                        ? <div>We'll automatically charge your card</div>
                        : <div>Amount paid</div>
                }
                <div>
                    {receipt.receipt.total_charged__currency}

                    <div className={styles.card_info}>
                        {receipt.receipt.last_4}
                        <Card name={receipt.receipt.payment_type as CardNames} />
                    </div>
                </div>
            </div>
        </>
    )
}

interface GratuityProps {
    amount: number
}

const Gratuity: React.FC<GratuityProps> = ({amount: amount}) => {
    const {percentage, updateTip} = useUpdateTip();

    const onClick = () => {
        if (percentage !== amount) {
            void updateTip(amount, 'percentage');
        }
    }

    return (
        <div className={clsx({[styles.selected]: percentage == amount})} onClick={onClick}>{amount}%</div>
    )
}

const Custom: React.FC = () => {
    const navigate = useNavigate();
    const {custom} = useUpdateTip();

    const onClick = () => {
        navigate('../tip');
    }

    return (
        <div className={clsx({[styles.selected]: !!custom})} onClick={onClick}>Custom</div>
    )
}

export default Details;
