import { ApiError } from './ApiError.ts';
import Config from './Config.ts';
import {
  ConfirmationResponse,
  PostApiV20Verification,
  PostApiV20Verify,
  ReservationResponse,
  RestaurantResponse,
  SetPaymentMethodResponse,
  SetupIntentResponse,
  AuthResponse,
  Post_Reserve,
  Post_Confirm,
  GetReservationResponse,
  Post_PaymentMethod,
  Post_UpdateUser,
  UpdateUserResponse,
  ReserveErrorResponse,
  UpdateUserErrorResponse,
  CardErrorResponse,
  GetUserResponse,
  Post_Claim,
  PaymentMethodsResponse,
  CancelConfirmResponse,
  CancelFinishResponse,
  Post_Cancel,
  ReservationReceiptResponse,
  Post_Tip,
  Post_AdditionalTip,
  ReservationPreviewResponse,
} from './types.ts';
import * as Sentry from '@sentry/react';

export type SearchParams =
  | undefined
  | Record<string, string | number | boolean | null | undefined>;

const API_URI = {
  RESTAURANT: {
    GET: (id: number) => `public/restaurant/${id}`,
    CONFIRM: (id: number) => `public/restaurant/${id}/confirm`,
    RESERVE: (id: number) => `public/restaurant/${id}/reserve`,
  },
  CONCIERGE_MEMBER: {
    PREVIEW: (uuid: string) =>
      `v20240524/concierge-reservations/${uuid}/preview`,
    RECEIPT: (uuid: string) =>
      `v20240524/concierge-reservations/${uuid}/receipt`,
    TIP: (uuid: string) => `v20240524/concierge-reservations/${uuid}/tip`,
    ADDITIONAL_TIP: (uuid: string) =>
      `v20240524/concierge-reservations/${uuid}/additional_tip`,
  },
  EVENT: {
    PREVIEW: (id: number, eventId: number) =>
      `restaurant/${id}/event/${eventId}/reserve/preview`,
    RESERVE: (id: number, eventId: number) =>
      `restaurant/${id}/event/${eventId}/reserve`,
  },
  RESERVATION: {
    PREVIEW: (uuid: string) => `v2.1/reservations/${uuid}/preview`,
    RECEIPT: (uuid: string) => `v2.1/reservations/${uuid}/receipt`,
    TIP: (uuid: string) => `v2.0/reservations/${uuid}/tip`,
    ADDITIONAL_TIP: (uuid: string) =>
      `v2.0/reservations/${uuid}/additional_tip`,
    CLAIM: (id: number, uuid: string) =>
      `public/reservation/${id}/${uuid}/claim`,
    CANCEL: {
      CONFIRM: (uuid: string, signature: string) =>
        `public/reservation/${uuid}/cancel-confirm?sig=${signature}`,
      FINISH: (uuid: string, signature: string) =>
        `public/reservation/${uuid}/cancel?sig=${signature}`,
    },
  },
  USER: {
    GET: () => 'user',
    RESERVATION: (uuid: string) => `user/reservation/${uuid}`,
    SETUP_INTENT: () => 'setup-intent',
    PAYMENT_METHOD: () => 'v2.0/billings',
    ADD_PAYMENT_METHOD: () => 'add-payment-method',
    UPDATE: () => 'user',
  },
  PHONE: {
    VERIFY: () => 'v2.0/verify',
    VERIFICATION: () => 'v2.0/verification',
  },
};

const headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
  'X-API-Version': '20240524',
};

const GET_OPTIONS: RequestInit = {
  method: 'GET',
  mode: 'cors',
  referrerPolicy: 'strict-origin-when-cross-origin',
};

const POST_OPTIONS: RequestInit = {
  method: 'POST',
  mode: 'cors',
  referrerPolicy: 'strict-origin-when-cross-origin',
};

const api = {
  get: async <ResponseT, SearchT extends SearchParams = undefined>(
    token: string | null,
    path: string,
    search_params?: SearchT
  ): Promise<ResponseT> => {
    const url = new URL(`${Config.data.uri.api}${path}`);
    if (search_params) {
      Object.keys(search_params)
        .filter((key) => !!search_params[key])
        .forEach((key) =>
          url.searchParams.append(key, '' + search_params[key])
        );
    }

    const options: RequestInit = {
      ...GET_OPTIONS,
      headers: {
        ...headers,
        'X-Dorsia-Client': `widget build:${Config.data.build},environment:${Config.data.environment}`,
      },
    };

    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    let json: ResponseT = {} as ResponseT;
    let response: Response | null = null;
    try {
      response = await fetch(url, options);
    } catch (e: unknown) {
      console.warn(
        'Uncaught Exception',
        url.toString(),
        options,
        e instanceof Error ? e.message : ''
      );
    }

    if (response) {
      try {
        json = (await response.json()) as ResponseT;
      } catch (e: unknown) {
        console.warn('Json Parsing issue', url.toString());
      }

      if (!response.ok) {
        const type = ApiError.match<ResponseT>(json);
        if (type) {
          if (type === 'UnfinishedUserProfileErrorResponse') {
            return json;
          }

          const resp = json as unknown as
            | ReserveErrorResponse
            | UpdateUserErrorResponse;
          throw new ApiError({ url, response: resp });
        }

        Sentry.captureMessage('api.get Error', {
          extra: {
            response: {
              status: response.status,
              statusText: response.statusText,
              body: json,
            },
          },
        });

        throw new Error(response.statusText);
      }
    }

    return json;
  },

  post: async <
    ResponseT,
    PostT = undefined,
    SearchT extends SearchParams = undefined
  >(
    token: string | null,
    path: string,
    search_params?: SearchT,
    post_params?: PostT
  ): Promise<ResponseT> => {
    const url = new URL(`${Config.data.uri.api}${path}`);
    if (search_params) {
      Object.keys(search_params).forEach((key) =>
        url.searchParams.append(key, '' + key)
      );
    }

    const options: RequestInit = {
      ...POST_OPTIONS,
      headers: {
        ...headers,
        'X-Dorsia-Client': `widget build:${Config.data.build},environment:${Config.data.environment}`,
      },
      body: JSON.stringify(post_params),
    };

    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    let json: ResponseT = {} as ResponseT;
    let response: Response | null = null;
    try {
      response = await fetch(url, options);
    } catch (e: unknown) {
      console.warn(
        'Uncaught Exception',
        url.toString(),
        options,
        e instanceof Error ? e.message : ''
      );
    }

    if (response) {
      try {
        json = (await response.json()) as ResponseT;
      } catch (e: unknown) {
        console.warn('Json Parsing issue', url.toString());
      }

      if (!response.ok) {
        const type = ApiError.match<ResponseT>(json);
        if (type) {
          const resp = json as unknown as
            | ReserveErrorResponse
            | UpdateUserErrorResponse
            | CardErrorResponse;
          throw new ApiError({ url, response: resp });
        }

        throw new Error(response.statusText);
      }
    }

    return json;
  },
};

export const getUserReservation = async (
  token: string,
  uuid: string
): Promise<GetReservationResponse> => {
  return api.get(token, API_URI.USER.RESERVATION(uuid));
};

export const createReservation = async (
  token: string,
  body: Post_Reserve
): Promise<ReservationResponse> => {
  return api.post(
    token,
    API_URI.RESTAURANT.RESERVE(body.restaurant),
    undefined,
    body
  );
};

export const createEventReservationPreview = async (
  token: string,
  body: Post_Reserve,
  eventId: number,
  restaurantId: number
): Promise<ReservationResponse> => {
  return api.post(
    token,
    API_URI.EVENT.PREVIEW(restaurantId, eventId),
    undefined,
    body
  );
};

export const createEventReservationBooking = async (
  token: string,
  body: Post_Reserve,
  eventId: number,
  restaurantId: number
): Promise<ReservationResponse> => {
  return api.post(
    token,
    API_URI.EVENT.RESERVE(restaurantId, eventId),
    undefined,
    body
  );
};

export const getReservationPreview = async (
  token: string,
  uuid: string
): Promise<ReservationPreviewResponse> => {
  return api.get(token, API_URI.RESERVATION.PREVIEW(uuid));
};

export const getReservationReceipt = async (
  token: string,
  uuid: string
): Promise<ReservationReceiptResponse> => {
  return api.get(token, API_URI.RESERVATION.RECEIPT(uuid));
};

export const updateReservationTip = async (
  token: string,
  uuid: string,
  body: Post_Tip
): Promise<ReservationReceiptResponse> => {
  return api.post(token, API_URI.RESERVATION.TIP(uuid), undefined, body);
};

export const updateReservationAdditionalTip = async (
  token: string,
  uuid: string,
  body: Post_AdditionalTip
): Promise<ReservationReceiptResponse> => {
  return api.post(
    token,
    API_URI.RESERVATION.ADDITIONAL_TIP(uuid),
    undefined,
    body
  );
};

export const getConciergeMemberReservationPreview = async (
  token: string,
  uuid: string
): Promise<ReservationPreviewResponse> => {
  return api.get(token, API_URI.CONCIERGE_MEMBER.PREVIEW(uuid));
};

export const getConciergeMemberReservationReceipt = async (
  token: string,
  uuid: string
): Promise<ReservationReceiptResponse> => {
  return api.get(token, API_URI.CONCIERGE_MEMBER.RECEIPT(uuid));
};

export const updateConciergeMemberReservationTip = async (
  token: string,
  uuid: string,
  body: Post_Tip
): Promise<ReservationReceiptResponse> => {
  return api.post(token, API_URI.CONCIERGE_MEMBER.TIP(uuid), undefined, body);
};

export const updateConciergeMemberReservationAdditionalTip = async (
  token: string,
  uuid: string,
  body: Post_AdditionalTip
): Promise<ReservationReceiptResponse> => {
  return api.post(
    token,
    API_URI.CONCIERGE_MEMBER.ADDITIONAL_TIP(uuid),
    undefined,
    body
  );
};

export const claimReservation = async (
  token: string,
  id: number,
  uuid: string,
  body: Post_Claim
): Promise<ReservationResponse> => {
  return api.post(token, API_URI.RESERVATION.CLAIM(id, uuid), undefined, body);
};

export const cancelReservationConfirm = async (
  signature: string,
  uuid: string
): Promise<CancelConfirmResponse> => {
  return api.post(null, API_URI.RESERVATION.CANCEL.CONFIRM(uuid, signature));
};

export const cancelReservationFinish = async (
  signature: string,
  uuid: string,
  body: Post_Cancel
): Promise<CancelFinishResponse> => {
  return api.post(
    null,
    API_URI.RESERVATION.CANCEL.FINISH(uuid, signature),
    undefined,
    body
  );
};

export const getReservationConfirmation = async (
  token: string,
  id: number,
  body: Post_Confirm
): Promise<ConfirmationResponse> => {
  return api.post(token, API_URI.RESTAURANT.CONFIRM(id), undefined, body);
};

export const getRestaurant = async (
  id: number | null,
  date: string,
  start_time: string,
  cache: string
): Promise<RestaurantResponse | null> => {
  if (!id) {
    return null;
  }

  if (!cache) {
    console.log('.');
  }

  const params = {
    date,
    start_time,
  };

  return await api.get(null, API_URI.RESTAURANT.GET(id), params);
};

export const setupIntent = async (
  token: string
): Promise<SetupIntentResponse> => {
  return await api.get(token, API_URI.USER.SETUP_INTENT());
};

export const getPaymentMethods = async (
  token: string
): Promise<PaymentMethodsResponse> => {
  return await api.get(token, API_URI.USER.PAYMENT_METHOD());
};

export const setPaymentMethod = async (
  token: string,
  body: Post_PaymentMethod
): Promise<SetPaymentMethodResponse> => {
  return await api.post(
    token,
    API_URI.USER.ADD_PAYMENT_METHOD(),
    undefined,
    body
  );
};

export const getUser = async (token: string): Promise<GetUserResponse> => {
  return await api.get(token, API_URI.USER.GET());
};

export const updateUser = async (
  token: string,
  body: Post_UpdateUser
): Promise<UpdateUserResponse> => {
  return await api.post(token, API_URI.USER.UPDATE(), undefined, body);
};

export const verify = async (phone: string, channel: string): Promise<null> => {
  return api.post<null, PostApiV20Verify>(
    null,
    API_URI.PHONE.VERIFY(),
    undefined,
    { phone: phone, channel: channel ? channel : null }
  );
};

export const verification = async (
  phone: string,
  code: string
): Promise<AuthResponse> => {
  return api.post<AuthResponse, PostApiV20Verification>(
    null,
    API_URI.PHONE.VERIFICATION(),
    undefined,
    { phone, code }
  );
};

export const graphqlAccessQuery = async (eventId: number) => {
  const sendQuery = `query Access($forEvent: ID!) {
      accessTypes(forEvent: $forEvent) {
        id
        name
        description
        price_summary
        guest_summary
    }
  }`;

  return await api.post(null, '../graphql/public', undefined, {
    query: sendQuery,
    variables: {
      forEvent: eventId,
    },
  });
};

export const graphqlTicketQuery = async (
  eventId: number,
  accessTypeId: number
) => {
  const sendQuery = `query TicketInventory($forEvent: ID!, $accessType: ID!) {
        ticketInventory(forEvent: $forEvent, forAccessType: $accessType) {
            id
            price
            price__currency
            sold_out
            guests
            maximum_purchasable
            item {
                name
                description
            }
            guests
        }
    }`;

  return await api.post(null, '../graphql/public', undefined, {
    query: sendQuery,
    variables: {
      forEvent: eventId,
      accessType: accessTypeId,
    },
  });
};
