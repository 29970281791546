import clsx from 'clsx';
import {useAtomValue, useAtom} from "jotai";
import React, {useState} from "react";
import {Scrollable} from "../../../components/Scrollable/Scrollable.tsx";
import {updateBookingUrlStateAtom} from "../lib/state.ts";
import {AvailabilityOption} from "../../../lib/types.ts";

import styles from './ScrollableItem.module.css';

import User_light from '../../../assets/images/User_light.svg?react';
import User_dark from '../../../assets/images/User_dark.svg?react';

interface Props {
    option: AvailabilityOption
}

const ReservationTables: React.FC = () => {
    const temp= useAtomValue(updateBookingUrlStateAtom);

    if (!temp.availability) {
        return <></>
    }

    return (
        <Scrollable>
            {temp.availability.options.map((option: AvailabilityOption) => <ReservationTable key={option.diff_key} option={option} />)}
        </Scrollable>
    )
}

const ReservationTable: React.FC<Props> = ({option}) => {
    const [temp, setTemp] = useAtom(updateBookingUrlStateAtom);
    const [hover, setHover] = useState<boolean>(false);

    const current_chosen = temp.option?.diff_key === option.diff_key;
    const active = current_chosen || hover;

    const onEnter = () => setHover(true);
    const onLeave = () => setHover(false);
    const onClick = () => setTemp({
        option
    });

    const className = clsx(
        styles.scrollableItem,
        {
            [styles.selected]: current_chosen,
            [styles.hover]: hover
        }
    );

    return (
        <div className={className} onClick={onClick} onMouseEnter={onEnter} onMouseLeave={onLeave}>
            <div className={styles.title}>
                {active ? <User_light /> : <User_dark />}
                <span>{option.seats}</span>
            </div>
            <div className={styles.text}>{option.price__currency}/person</div>
            {!!option.type && (
                <div className={styles.text}>{option.type}</div>
            )}
        </div>
    );
}

export default ReservationTables;